
<template>
  <div class="container">
    <div class="title">
      <span class="title-left"> <img src="../../../assets/img/company/BUSZI.png" alt="" style="margin-right:8px">
        Business Operations</span>


    </div>
    <div class="table">
      <div class="right_cont_box">
        <div class="table_box">
          <el-card class="box-card">
            <div v-for="(item, index) in businesslist" :key="index" class="text item">
              <span class="card-title">{{ item.name }}</span>
              <div class="card-text">{{ item.text }}</div>
            </div>
          </el-card>
        </div>
      </div>
    </div>



  </div>
</template>
<script>

import crypto from '@/utils/crypto'
import { getBusinessOperations } from "../../../api/companyApi";

export default {

  data() {
    return {
      businesslist: [{ name: 'Line of Business:', text: '' },
      { name: 'Products/Services:', text: "" },

      { name: 'Employees:', text: '' },
      { name: 'Listed:', text: '' },
      { name: 'Last Update:', text: '' }
      ],
      loginStatus: false,//登录状态


      icon: '',

    }
  },
  computed: {

  },
  watch: {

    $route: {
     
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    this.getlist()
  },

  methods: {
    getlist() {

      const params = {
        id3a : this.$route.query.id3a,
        companyCountry : this.$route.query.companyCountry,
        // id3a: JSON.parse(sessionStorage.getItem("company")).aaa_id,
        // companyCountry: JSON.parse(sessionStorage.getItem("company")).company_country,

      }
      getBusinessOperations(params).then(
        res => {
          let data = JSON.parse(crypto.decrypt(res))
          if (data.code) {
            this.businesslist.map((item, index) => {
              // console.log(item.name);
              if (item.name == 'Line of Business:') {
                item.text = data.data.business
              }
              if (item.name == 'Products/Services:') {
                item.text = data.data.products
              }
              if (item.name == 'Employees:') {
                item.text = data.data.employees
              }
              if (item.name == 'Listed:') {
                item.text = data.data.listed
              }
              if (item.name == 'Last Update:') {
                item.text = data.data.lastUpdate
              }
            })
          } else {
            this.businesslist = []
          }


          // this.parentTable1=data.data
        }
      )


    },

    loginPop() {

    },
  },

}
</script>
<style scoped lang="less">
.cont_m {
  margin-top: 30px;
  margin-bottom: 80px;
}

.Company Snapshot {}

.Country Data {}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 69px;

  .title-left {
    display: flex;
    font-size: 16px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #022955;
  }
}

.table {
  margin-top: 20px;
}

.el-card {
  border: none;
}

.el-card.is-always-shadow {
  box-shadow: none;

  /deep/ .el-card__body {
    padding: 0px;
  }
}

.card-title {
  font-size: 16px;
  font-family: ArialMT;
  color: #8497AB;
  width: 194px;
  height: 24px;
  margin-right: 40px;
}

.text {
  font-size: 16px;
  font-family: ArialMT;
  color: #022955;
  display: flex;
  padding: 20px 0 20px 10px;
  border-bottom: 1px solid #E7EAED;
}

.text:nth-last-of-type(1) {
  border-bottom: none;
}

.card-text {
  width: 736px;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #022955;
  line-height: 27px;
}
@media (max-width: 821px) {

.text{
 flex-wrap: wrap;
 padding-bottom: 10px !important;
}
.title{
  margin-top: 20px !important;
}
.card-title {
  font-size: 14px !important;
      color:  #022955 !important;
}
.card-text {
   font-size: 12px !important;
   color: #8497AB !important;

}
}
</style>